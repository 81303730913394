const main = {
    main: {
        txt_01: "offers online services to healthcare providers.",
        txt_02: "Our platform allows healthcare professionals to treat patients at a distance",
        txt_03: " using telecommunications technology.",
        txt_04: "",
        txt_05: "helps rehabilitation non-face-to-face."
    },
    clinic: {
        txt_01: "About",
        txt_02: "rebless™ Clinic",
        txt_03: "The rebless™ Clinic web-based app is used by providers to prescribe exercise regimens, manage and export patient output, and perform telemedicine visits.",
        txt_04: "Connect virtually via video conferencing with patients using the rebless™ app",
        txt_05: "Schedule patients’ telemedicine visits",
        txt_06: "Assign therapy regimen",
        txt_07: "Treatment tracking and reporting"
    },
    app: {
        txt_01: "rebless™ App",
        txt_02: "(for Patients)",
        txt_03: "Patients use the rebless™ app to operate the device, track his/her improvements, and connect with a medical provider virtually.",
        txt_04: "Tele-rehab video call with your doctor",
        txt_05: "Timely care in an efficient manner",
        txt_06: "ROM measurement: Passive and Active modes"
    },
    faq: {
        click: "Click",
        question: {
            txt_01: "Where can I learn more about the rebless™ device?",
            txt_02: "Who can use rebless™ Clinic?",
            txt_03: "How can the clinician find his/her patients in the rebless™ Clinic application?"
        },
        answer: {
            txt_01: "To learn more about the rebless™ device, visit here: ",
            txt_02: "Any licensed PT or OT can use rebless™ Clinic to monitor and treat their patients.",
            txt_03: "Patients are identified by their email address and use the same ID within their rebless™ app."
        }
    }
}

export default main;
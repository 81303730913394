const main = {
    main : {
        txt_01: "의료진과 환자를 이어줍니다.",
        txt_02: "효율적인 환자관리, 운동 모니터링",
        txt_03 : "그리고 화상 상담 서비스를",
        txt_04: "무료로 경험해 보세요.",
        txt_05: "비대면으로 재활운동을 도와줍니다."
    },
    clinic: {
        txt_01: "리블레스 클리닉,",
        txt_02: "무엇을 할 수 있을까요?",
        txt_03: "리블레스 클리닉은 웹 기반 앱으로 의료진이 운동을 모니터링하고, 원격 상담 서비스를 할 수 있습니다.",
        txt_04: "리블레스 앱을 사용하여 화상 상담",
        txt_05: "원격 상담 일정 관리",
        txt_06: "재활운동 기록 분석 및 레포트",
        txt_07: ""
    },
    app: {
        txt_01: "리블레스 모바일 앱",
        txt_02: "",
        txt_03: "리블레스 앱을 이용하여 손쉽게 장비를 작동하고 재활 기록을 관리할 수 있으며 비대면 상담 서비스 기능을 통해 재활 상담을 받을 수 있습니다.",
        txt_04: "리블레스 클리닉을 이용하는 의료진과 상담 서비스",
        txt_05: "일일 재활 목표 설정",
        txt_06: "ROM 측정 : PROM – Passive, AROM - Active"
    },
    faq: {
        click : "클릭",
        question : {
            txt_01 : "리블레스 홈에 대해서 알고싶어요.",
            txt_02 : "리블레스 클리닉은 누구를 위한 플랫폼인가요?",
            txt_03 : "환자와 연동은 어떻게 되는건가요?"
        },
        answer : {
            txt_01 : "리블레스 홈 자세히 보기, ",
            txt_02 : "리블레스 클리닉은 리블레스 홈을 이용하여 재활운동을 하는 환자들을 손쉽게 관리할 수 있는, 의료진용 환자 관리 서비스입니다.",
            txt_03 : "리블레스앱에 회원가입한 환자의 이메일을 입력하면 자동으로 연동 됩니다."
        }
    },
}

export default main;
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {kr, en} from './index';
import {browserLang} from '@/comm/lib/static';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('i18nextLng') || browserLang(),
        resources: {
            kr,
            en
        },
        fallbackLng: [
            'ko',
            'en'
        ]
    });
import React, {
    Suspense,
    lazy,
    useEffect
} from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';

// common components
const Header = lazy(() => import('@/components/Header'));
const Footer = lazy(() => import('@/components/Footer'));

// region components
const Main = lazy( () => import('@/page/Main'));
const Privacy = lazy( () => import('@/page/Privacy'))

function Routes() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Router>
            <Suspense fallback={<></>}>
                <Header/>
                <Switch>
                    <Route exact path="/" component={Main}/>

                    <Route exact path="/policy" component={Privacy}/>
                    <Route path="/policy/:item" component={Privacy}/>

                    <Redirect from="*" to="/"/>
                </Switch>
                <Footer/>
            </Suspense>
        </Router>
    )
}

export default Routes;
import i18n from "i18next";
import moment from 'moment-timezone';

export const _REBLESS_MANAGER_LINK = 'https://manager.rebless.clinic';
export const _AOS_MARKET_LINK = 'https://play.google.com/store/apps/details?id=com.hrobotics.rebless.bluetooth';
export const _IOS_MARKET_LINK = 'https://apps.apple.com/kr/app/rebless-bluetooth/id1592072249';
export const _TIMEZONE = moment.tz.guess();

export const browserLang = () => {
    return navigator.language.toLocaleLowerCase().substring(0, 2) === 'ko' ? 'kr' : 'en';
}

export const changeLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang)
        .then(r => {
            document.getElementsByTagName('html')[0].setAttribute('lang', lang);
        });
}